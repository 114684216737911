import Routes from "@shared/Routes";
import messages_sv from "@translation/sv.json";

export const SVLANG = messages_sv;
export const ROUTES = Routes;

export const ENVIRONMENTS = {
  Development: "dev",
  Stage: "stage",
  Production: "prod",
};

export const INPUT = {
  NAME: {
    EMAIL: "email",
    PASSWORD: "password",
    NAME: "name",
    USER_NAME: "username",
    MESSAGE: "message",
    PERSONAL_NUMBER: "personalNumber",
    ROLE: "role",
    BANK_ID_TYPE: "bankIdType",
  },
  TYPE: {
    TEXT: "text",
    PASSWORD: "password",
    NUMBER: "number",
  },
};

export const APPLICATION_NAME = "Svenska Fribrevsbolaget";

export const CUSTOMER_SERVICE_EMAIL = "kundservice@svenskafribrevsbolaget.se";

export const TITLE_DIVIDER = " | ";

export const PAGE_NOT_FOUND_CODE = 404;

export const FILE_SIZE_LIMIT = 2000000;

export const ACCEPT_FILE_TYPES = [
  ".doc",
  ".docx",
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/jpeg",
  "image/png",
  "image/jpg",
];
export const ORDINARY = "Ordinarie";

export const GRID = "GRID";

export const NAV_BOTTOM_MENU_ITEMS = [
  {
    label: SVLANG.new_customer,
    redirectTo: Routes.SEARCH.URL,
    icon: " icon ph-magnifying-glass-bold",
  },
  {
    label: SVLANG.log_out,
    redirectTo: Routes.LOGIN.URL,
    icon: " icon ph-sign-out-bold",
  },
];

export const HEADER_MENU_ITEMS = [
  {
    icon: " icon ph-sign-out-bold",
    redirectTo: Routes.LOGIN.URL,
    label: SVLANG.log_out,
  },
];

export const MONTHS = [
  SVLANG.january,
  SVLANG.february,
  SVLANG.march,
  SVLANG.april,
  SVLANG.may,
  SVLANG.june,
  SVLANG.july,
  SVLANG.august,
  SVLANG.september,
  SVLANG.october,
  SVLANG.november,
  SVLANG.december,
];

export const USER_ROLE = {
  ADMIN: "1",
  CUSTOMER: "2",
  ADVISOR: "5",
};

export const SURVEY_STATUS = {
  ACTIVE: 0,
  PREVIEW: 1,
  COMPLETE: 2,
};

export const RISK_VALUES = [1, 2, 3, 4, 5, 6, 7];

export const FUNDS = "funds";

export const REBALANCE = "rebalance";

export const TYPE = "type";

export const ID = "id";

export const UPDATE = "update";

export const CHANGE_FUND_PORTFOLIO = "change_fund_portfolio";

export const CHANGE_FUNDS = "change_funds";

export const APPLIES_TO_FUTURE = "APPLIES_TO_FUTURE";

export const APPLIES_TO_CURRENT_FUTURE = "APPLIES_TO_CURRENT_FUTURE";

export const RISK = "risk";

export const RATING = "rating";

export const PERCENTAGE = "percentage";

export const DOWNLOAD = "download";

export const ICON = "icon";

export const FEE_FUND_MANAGEMENT = "Avgift, fond, förvaltning, rörlig";

export const FIXED_ANNUAL_FEE = "Fast årlig avgift";

export const THREE_YEARS = "three_years";

export const ONE_YEAR = "one_year";

export const FIVE_YEARS = "five_years";

export const ONE_MONTH = "one_month";

export const SIX_MONTHS = "six_months";

export const SPECIAL = "Special";

export const CURRENCY_CODE = "kr";

export const PERCENT_SIGN = "%";

export const HOME = "Mitt sparande";

export const MY_PROFILE = "Min profil";

export const MY_INSURANCE = "Mina försäkringar";

export const FUND = "Fonder";

export const FUND_PORTFOLIO = "Fondportföljer";

export const SUPPORT = "Support";

export const INSURANCE = "Försäkring";

export const NOTIFICATION = "Mina meddelanden";

export const BANKID = "bankid";

export const ACCOUNTS = "accounts";

export const ALLOCATION = "allocation";

export const TOKEN = "Token";

export const IDENTITY_ID = "IdentityId";

export const BRICK_ID = "brickId";

export const yearToDayFormat = "yyyy-M-dd";

export const ALLOCATION_ORDER_CREATED = "AllocationOrder_Created";

export const ALLOCATION_ORDER_ONGOING = "AllocationOrder_OngoingAllocation";

export const LOGIN_USING_OTHER_MOBILE = "login_using_other_mobile";

export const MOBILE = "Mobile";

export const FILE = "File";

export const AWS_CREDENTIALS = "aws-credentials";

export const BFS_PN = "bfs-pn";

export const ACTIVE = "active";

export const HELP_DESK_ID = "054ba150-b7cc-422f-80ad-415f94a57cd5";

export const internal_server_error = "Request failed with status code 502";

export const BACKGROUND_AND_PRODUCTS = "bilaga-b-produkter";

export const ADVISOR_COMPANY = "Svenska Fribrevsbolaget Försäkring AB";

export const BANK_TRANSFER = "5023-8468";

export const ADVISOR_COMPANY_NO = "559051-4906";

export const NEW_INSURANCE_COMPANY_ADDRESS = "Ingenjör Bååths Gata 41";

export const NEW_INSURANCE_COMPANY_PINCODE = "722 12 Västerås";

export const NEW_INSURANCE_COMPANY_EMAIL =
  "kundservice@svenskafribrevsbolaget.se";

export const BACK_OFFICE_NAME = "SFB Kundservice";

export const BACK_OFFICE_EMAIL = "max.flodem@svenskafribrevsbolaget.se";

export const BACK_OFFICE_NO = "+46705116117";

export const CLOSED = "closed";

export const OPEN = "open";

export const PRIVATE_OWNED = "private_owned";

export const COMPANY_OWNED = "company_owned";

export const PREVIEW_PAGE = "all";

export const PAYOUT_START = 65;

export const PAYOUT_PERIOD = 20;

export const INPUT_YES = ["yes"];

export const INPUT_NO = "no";

export const DEFAULT_FORM = ["item1"];

export const NONE = "none";

export const BOTTOM = "BOTTOM";

export const STRING = "string";

export const PENSION = "pension";

export const TRANSFER = ["transfer"];

export const REQUIRED = "required";

export const CUSTOM = "custom";

export const REQUIRED_ONE = "requireoneanswer";

export const REQUIRED_NUMBER = "requirenumeric";

export const NEW = "new";

export const INPUT_TRANSFER = "transfer";

export const CHANGE = "change";

export const NEW_SUBSCRIPTION_T = "Nyteckning T";

export const NEW_SUBSCRIPTION_P = "Nyteckning P";

export const NEW_SUBSCRIPTION_K = "Nyteckning K";

export const AS_SOON_AS = "Snarast möjligt";

export const BFS_EPOCH = "bfs-epoch";
